/**
 * Feature flag type values used in application front-end.
 */
export enum FF {
  FILTERS_V1 = 'FF_FILTERS_V1',
  // Same as in back-end FeatureFlag/Flag.php!
  PROBLEEMIDE_HALDUS = 'FF_PROBLEEMIDE_HALDUS',
  TAITMISREGISTER_ANDMEVAHETUS = 'FF_TAITMISREGISTER_ANDMEVAHETUS',
  EAREST_DISABLE_SENDING = 'FF_EAREST_DISABLE_SENDING',
  EAREST_DISABLE_RECEIVING = 'FF_EAREST_DISABLE_RECEIVING',
}
