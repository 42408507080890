<template>
  <div>
    <DataTable
      :selection.sync="selectedIds"
      :value="caseList"
      responsive-layout="scroll"
      :loading="isLoading"
      :row-hover="true"
      :paginator="true"
      data-key="id"
      :scrollable="true"
      scroll-height="500px"
      :rows="perPage"
      :lazy="true"
      :total-records="totalRecords"
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rows-per-page-options="[10, 25, 50]"
      class="p-datatable-striped"
      @page="onPage($event)"
    >
      <Column
        :selection-mode="singleCase ? 'single' : 'multiple'"
        header-style="width: 3em"
      ></Column>
      <Column :header="$t('dashboard.table.date')" header-style="width: 8em">
        <template #body="slotProps">
          <span v-if="slotProps.data.case_number">{{
            getFormattedDate(slotProps.data.case_date_opened)
          }}</span>
          <router-link v-else :to="`/case/${slotProps.data.case_id}`">
            <Button class="p-button-raised p-button-text">
              <a>{{ $t('open') }}</a>
            </Button>
          </router-link>
        </template>
      </Column>
      <Column v-if="tag === 5" :header="$t('dashboard.table.taitisNumber')">
        <template #body="slotProps">
          {{ slotProps.data.case_taitis_number }}
        </template>
      </Column>
      <Column :header="$t('dashboard.table.caseNumber')">
        <template #body="slotProps">
          <span v-if="slotProps.data.case_number">
            <a href="#" @click="openLink(slotProps.data.case_id)">{{
              slotProps.data.case_number
            }}</a>
          </span>
          <span v-else> </span>
        </template>
      </Column>
      <Column :header="$t('case.table.claimant')">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.case_claimant_name }}
            <div class="td-sub">
              {{ slotProps.data.case_claimant_reg_code }}
            </div>
          </div>
        </template>
      </Column>
      <Column :header="$t('case.table.debtor')">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.case_debtor_name }}
            <div class="td-sub">
              {{ slotProps.data.case_debtor_reg_code }}
            </div>
          </div>
        </template>
      </Column>
      <Column :header="$t('dashboard.table.claim')">
        <template #body="slotProps">
          <div>
            {{ getMonetaryValue(slotProps.data.case_balance) }}
            <div class="td-sub">
              {{
                `${getMonetaryValue(slotProps.data.case_total_claims)}
          + ${getMonetaryValue(slotProps.data.case_total_fees)}`
              }}
            </div>
          </div>
        </template>
      </Column>
      <Column v-if="tag === 6" :header="$t('dashboard.table.reason')">
        <template #body="slotProps">
          {{ getReason(slotProps.data) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import { getFormattedDateTime, getFormattedDate } from '@/mixins/dateTime';
import { Tags } from '@/static/enums/case';
import TagApi from '../../api/Tag';
import EventBus from '../../mixins/event-bus';

export default {
  name: 'CaseTable',
  props: {
    selectedCaseIds: {
      type: Array,
      required: true,
    },
    singleCase: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      caseList: [],
      isLoading: false,
      selectedIds: [],
      totalRecords: 0,
      sortField: 'c.number',
      sortOrder: -1,
      isErrorLogViewModalOpen: false,
      perPage: 25,
      page: 0,
    };
  },
  watch: {
    tag() {
      this.reset();
    },
    selectedIds: function () {
      if (!this.selectedIds) {
        return;
      }
      this.$emit(
        'selectCases',
        // NB: id = tag id
        //     case_id = case id
        this.selectedIds.map((item) => item.case_id)
      );
    },
  },
  beforeDestroy() {
    this.$emit('selectCases', []);
  },
  mounted() {
    EventBus.$on('RELOAD_DASHBOARD_CASELIST', () => {
      this.reset();
    });
    if (this.tag === 5) {
      this.sortField = 'c.taitisNumber';
    } else {
      this.sortField = 'c.number';
    }
    this.getData();
    this.selectedIds = this.selectedCaseIds;
  },
  methods: {
    getData() {
      this.isLoading = true;
      TagApi.find(this.getParams())
        .then((response) => {
          this.page = response.data.current_page;
          this.totalRecords = response.data.total_item_count;
          this.caseList = response.data.items;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getParams() {
      return {
        limit: this.perPage,
        group: 'dashboard',
        page: this.page + 1,
        sort:
          this.sortField !== '' && this.sortField !== null
            ? this.sortField
            : 'c.number',
        direction: this.sortOrder === 1 ? 'ASC' : 'DESC',
        ...this.getFilters(),
      };
    },
    getFilters() {
      const tags = this.tag ? [this.tag] : Object.values(Tags);
      if (this.tag === null) {
        return {
          status: 0,
        };
      }
      return {
        value: tags,
        status: 0,
      };
    },
    onCaseToggle(id) {
      this.$emit('toggleCase', id);
    },
    getMonetaryValue(value) {
      return value && value.cents ? value.cents / 100 : 0;
    },
    reset() {
      if (this.tag === 5) {
        this.sortField = 'c.taitisNumber';
      } else {
        this.sortField = 'c.number';
      }
      this.$emit('selectCases', []);
      this.selectedIds = null;
      this.page = 0;
      this.getData();
    },
    openLink(id) {
      const routeData = this.$router.resolve({ name: 'Case', params: { id } });
      window.open(routeData.href, '_blank');
    },
    getReason(data) {
      if (undefined === data.reason || data.reason === '') {
        return '';
      }
      try {
        let message = data.reason;
        let newMessage = '';
        message = Object.entries(JSON.parse(message));
        message.forEach((item) => {
          newMessage = `${newMessage}\n${this.$t(item[0])}:${item[1]}`;
        });
        return newMessage;
      } catch (e) {
        return data.reason;
      }
    },
    onPage(event) {
      this.perPage = event.rows;
      this.page = event.page;
      this.getData();
    },
    getFormattedDateTime,
    getFormattedDate,
  },
};
</script>
